import { Box, useMediaQuery, Typography, Grid, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { theme } from "./ui/theme";
import { format, parseISO } from "date-fns";

export default function ChequeStatus(props) {
  const match = useMediaQuery(theme.breakpoints.down("md"));
  const { dataAll } = props;

  // console.log(dataAll);
  return (
    <>
      <Grid container spacing={2}>
        {match ? null : (
          <Grid
            item
            md
            lg={3}
            sm={4}
            xs={6}
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          ></Grid>
        )}

        <Grid
          item
          md={12}
          lg={6}
          sm={8}
          xs={12}
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          {!!dataAll
            ? dataAll.map((dataAll, index) => (
                <Box
                  sx={{
                    width: "100%",
                    borderRadius: 1,
                    border: 0.1,
                    borderColor: "error.main",
                    mb: 1,
                    mt: 1,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          fontWeight: "700",
                          mt: 4,
                          mb: 4,
                          textAlign: "right",

                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            fontWeight: "700",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            fontWeight: "700",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            fontWeight: "700",
                          },
                        }}
                      >
                        เลขที่เช็ค :
                      </Typography>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          fontWeight: "700",
                          mt: 4,
                          mb: 4,
                          textAlign: "left",
                          color: "red",

                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            fontWeight: "700",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            fontWeight: "700",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            fontWeight: "700",
                          },
                        }}
                      >
                        {!!dataAll && !!dataAll.RefNo ? dataAll.RefNo : "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ mb: 2 }} />
                  <Grid container spacing={2}>
                    <Grid item lg={5} md={5} sm={5} xs={5}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "right",
                          mx: 1,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                        }}
                      >
                        วันที่เช็ค
                      </Typography>
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "left",
                          mx: 1,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                        }}
                      >
                        {!!dataAll && !!dataAll.ChqDate
                          ? format(parseISO(dataAll.ChqDate), "dd-MM-yyyy")
                          : "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ mb: 2, mt: 2 }} />
                  <Grid container spacing={2}>
                    <Grid item lg={5} md={5} sm={5} xs={5}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "right",
                          mx: 1,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                        }}
                      >
                        ธนาคาร
                      </Typography>
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "left",
                          mx: 1,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                        }}
                      >
                        {!!dataAll && !!dataAll.CustBankName
                          ? dataAll.CustBankName
                          : "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ mb: 2, mt: 2 }} />
                  <Grid container spacing={2}>
                    <Grid item lg={5} md={5} sm={5} xs={5}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "right",
                          mx: 1,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                        }}
                      >
                        สาขา
                      </Typography>
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "left",
                          mx: 1,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                        }}
                      >
                        {!!dataAll && !!dataAll.CustBankBranch
                          ? dataAll.CustBankBranch
                          : "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ mb: 2, mt: 2 }} />
                  <Grid container spacing={2}>
                    <Grid item lg={5} md={5} sm={5} xs={5}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "right",
                          mx: 1,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                        }}
                      >
                        จำนวนเงิน
                      </Typography>
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "left",
                          mx: 1,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                        }}
                      >
                        {!!dataAll && !!dataAll.PayAmt
                          ? dataAll.PayAmt.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ mb: 2, mt: 2 }} />
                  <Grid container spacing={2}>
                    <Grid item lg={5} md={5} sm={5} xs={5}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "right",
                          mx: 1,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                        }}
                      >
                        สถานะ
                      </Typography>
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "left",
                          mx: 1,

                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                        }}
                      >
                        {!!dataAll && !!dataAll.status ? dataAll.status : "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ mt: 2, mb: 2 }} />
                </Box>
              ))
            : []}
        </Grid>
      </Grid>
    </>
  );
}
