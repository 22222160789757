import {
  Box,
  useMediaQuery,
  AppBar,
  Toolbar,
  Typography,
  Grid,
  Container,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import logoOranoss from "../asset/Logo-Oranoss.png";
import { theme } from "../component/ui/theme";
import PointData from "../component/PointData";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import liff from '@line/liff'

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV !== "production"
      ? `${process.env.REACT_APP_BASE_URL_DEV}`
      : `${process.env.REACT_APP_BASE_URL_PROD}`,
});

export default function Point() {
  const match = useMediaQuery(theme.breakpoints.down("md"));
  const [allData, setAllData] = useState({
    totalPoint: "0",
    collectPoint: "0",
  });
  const [pointName, setPointName] = useState([]);
  const [contract, setContract] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState(searchParams.get("uId"));
  const [expirePoint, setExpirePoint] = useState({ point: "0", date: "-" });

  useEffect(() => {
    let pointName = {};
    let minISODate = [];
    liff.init({ liffId: `${process.env.REACT_APP_LIFF_ID}` }, async () => {
      if (liff.isLoggedIn()) {
        const getCreditLimitData = async () => {
          const profile = await liff.getProfile();
          const userId = profile.userId;
          const res = await instance.post("/register/point-history", {
            userId: userId,
            // userId: "U3f7fc91b00113bc142d776d58b545c4f",
            // modularId:
            //   "LU7dba45292d4cee4e7af3c9d0981af645-Uf37e44904f19b3fa4d40aebb3705dde8",
          });

          // console.log(res.data.results[0]);
          const data = res.data.data;

          for (let i = 0; i < data.length; i++) {
            if (data[i].PointName === "PointTotal") {
              setAllData((allData) => ({
                ...allData,
                totalPoint: data[i].QtyPoint,
              }));
            }
            if (data[i].PointName === "PointPending") {
              setAllData((allData) => ({
                ...allData,
                collectPoint: data[i].QtyPoint,
              }));
            }
            if (data[i].Line === "Detail") {
              minISODate.push(data[i].Date);
              if (!pointName.hasOwnProperty(data[i].PointName)) {
                pointName[data[i].PointName] = data[i].QtyPoint;
              } else {
                pointName[data[i].PointName] =
                  pointName[data[i].PointName] + data[i].QtyPoint;
              }
            }
          }
          minISODate = minISODate.sort()[0];
          let arrayPointName = Object.entries(pointName).map(
            ([type, value]) => ({
              type,
              value,
            })
          );

          if (minISODate) {
            let minDate = new Date(minISODate).toISOString().substring(0, 10);
            minDate = minDate.split("-");
            minDate = `${minDate[2]}-${minDate[1]}-${minDate[0]}`;
            console.log(minDate);
            setExpirePoint((expirePoint) => ({
              ...expirePoint,
              date: minDate,
            }));
          } else {
            setExpirePoint((expirePoint) => ({
              ...expirePoint,
              date: "-",
            }));
          }

          let expirePointData = 0;
          for (let i = 0; i < data.length; i++) {
            if (data[i].Date === minISODate && data[i].Line === "Detail") {
              expirePointData += data[i].QtyPoint;
            }
          }
          setExpirePoint((expirePoint) => ({
            ...expirePoint,
            point: expirePointData,
          }));

          let contractID = data
            .filter((item) => item.PointName === "CONTRACT ( สัญญา )")
            .map((item) => item.Line.split(":")[1]);

          let contractData = [];

          for (let i = 0; i < contractID.length; i++) {
            contractData.push({
              id: 0,
              contract: "",
              date: "",
              collectPoint: 0,
              collectMore: 0,
            });
            for (let j = 0; j < data.length; j++) {
              if (data[j].Line === `Line - #1:${contractID[i]}`) {
                contractData[i].id = data[j].Line;
                contractData[i].contract = data[j].QtyPoint;
                let date = data[j].Date.split("T")[0].split("-");
                contractData[i].date = `${date[2]}-${date[1]}-${date[0]}`;
              }
              if (data[j].Line === `Line - #2:${contractID[i]}`) {
                contractData[i].collectPoint = data[j].QtyPoint;
              }
              if (data[j].Line === `Line - #3:${contractID[i]}`) {
                contractData[i].collectMore = data[j].QtyPoint;
              }
            }
          }

          // console.log(contractData);

          setPointName(arrayPointName);
          setContract(contractData);
        };

        getCreditLimitData();
      } else {
        liff.login({ redirectUri: window.location.href });
      }
    });
  }, []);

  return (
    <>
      <Box sx={{ backgroundColor: "#E1E1E1" }}>
        {match ? null : (
          <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" elevation={0}>
              <Toolbar sm={12} xs={12} sx={{ background: "#8FC1EB" }}>
                <Box
                  component="img"
                  alt="logo"
                  src={logoOranoss}
                  sx={{ width: 30, mr: 1 }}
                />
                <Typography sx={{ fontFamily: theme.typography, fontSize: 16 }}>
                  Oranoss Co.,LTD.
                </Typography>
              </Toolbar>
            </AppBar>
          </Box>
        )}

        <Container sx={{ bgcolor: "white", height: "100vh" }}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{
                width: "30%",
                [theme.breakpoints.down("md")]: {
                  width: "60%",
                },
                [theme.breakpoints.only("sm")]: {
                  width: "50%",
                },
                [theme.breakpoints.only("xs")]: {
                  width: "60%",
                },
              }}
            >
              <Typography
                sx={{
                  ...theme.typography.registerLabel,
                  fontSize: 20,
                  fontWeight: "700",
                  mt: 4,
                  mb: 4,
                  textAlign: "center",
                  bgcolor: "red",
                  color: "white",
                  borderRadius: 10,
                  p: 1,

                  [theme.breakpoints.down("md")]: {
                    fontSize: "1rem",
                    fontWeight: "700",
                  },
                  [theme.breakpoints.only("sm")]: {
                    fontSize: "1rem",
                    fontWeight: "700",
                  },
                  [theme.breakpoints.only("xs")]: {
                    fontSize: "1rem",
                    fontWeight: "700",
                  },
                }}
              >
                ประวัติคะแนน
              </Typography>
            </Box>
          </Grid>

          <Grid item md={12} sm={12} lg={12} xs={12}>
            <PointData
              dataAll={allData}
              pointName={pointName}
              expirePoint={expirePoint}
              contract={contract}
            />
          </Grid>
        </Container>
      </Box>
    </>
  );
}
