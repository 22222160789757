import { Box, useMediaQuery, Typography, Grid } from "@mui/material";
import React from "react";
import { theme } from "./ui/theme";

export default function PointData(props) {
  const match = useMediaQuery(theme.breakpoints.down("md"));
  const { dataAll, pointName, expirePoint, contract } = props;
  console.log(expirePoint);

  return (
    <>
      <Grid container spacing={2}>
        {match ? null : (
          <Grid
            item
            md
            lg={3}
            sm={4}
            xs={6}
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          ></Grid>
        )}

        <Grid
          item
          md={12}
          lg={6}
          sm={8}
          xs={12}
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            {/* คะแนนสะสมที่ใช้ได้ */}
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    textAlign: "left",
                    color: "green",
                    mx: 1,
                    [theme.breakpoints.down("md")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                  }}
                >
                  คะแนนสะสมที่ใช้ได้
                </Typography>
              </Grid>

              <Grid item lg={3} md={3} sm={3} xs={3}>
                <Typography
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    textAlign: "right",
                    color: "green",
                    mx: 1,
                    [theme.breakpoints.down("md")]: {
                      fontSize: "1rem",
                      textAlign: "right",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "1rem",
                      textAlign: "right",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "1rem",
                      textAlign: "right",
                    },
                  }}
                >
                  {dataAll.totalPoint}
                </Typography>
              </Grid>
              <Grid item lg={3} md={3} sm={3} xs={3}>
                <Typography
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    textAlign: "right",
                    color: "green",
                    mx: 1,
                    [theme.breakpoints.down("md")]: {
                      fontSize: "1rem",
                      textAlign: "right",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "1rem",
                      textAlign: "right",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "1rem",
                      textAlign: "right",
                    },
                  }}
                >
                  คะแนน
                </Typography>
              </Grid>

              {!!pointName
                ? pointName.map((data, index) => (
                    <>
                      <Grid item lg={6} md={6} sm={6} xs={6} key={index}>
                        <Typography
                          sx={{
                            ...theme.typography.registerLabel,
                            fontSize: 20,
                            textAlign: "left",
                            color: "#BCBEC0",
                            mx: 1,
                            mt: 1,

                            [theme.breakpoints.down("md")]: {
                              fontSize: "1rem",
                              textAlign: "left",
                            },
                            [theme.breakpoints.only("sm")]: {
                              fontSize: "1rem",
                              textAlign: "left",
                            },
                            [theme.breakpoints.only("xs")]: {
                              fontSize: "1rem",
                              textAlign: "left",
                            },
                          }}
                        >
                          {!!data.type ? data.type : "0"}
                        </Typography>
                      </Grid>

                      <Grid item lg={3} md={3} sm={3} xs={3}>
                        <Typography
                          sx={{
                            ...theme.typography.registerLabel,
                            fontSize: 20,
                            textAlign: "right",
                            color: "#BCBEC0",
                            mx: 1,
                            mt: 1,

                            [theme.breakpoints.down("md")]: {
                              fontSize: "1rem",
                              textAlign: "right",
                            },
                            [theme.breakpoints.only("sm")]: {
                              fontSize: "1rem",
                              textAlign: "right",
                            },
                            [theme.breakpoints.only("xs")]: {
                              fontSize: "1rem",
                              textAlign: "right",
                            },
                          }}
                        >
                          {!!data.value ? data.value : "-"}
                        </Typography>
                      </Grid>

                      <Grid item lg={3} md={3} sm={3} xs={3}>
                        <Typography
                          sx={{
                            ...theme.typography.registerLabel,
                            fontSize: 20,
                            textAlign: "right",
                            color: "#BCBEC0",
                            mx: 1,
                            mt: 1,
                            mb: 1,
                            [theme.breakpoints.down("md")]: {
                              fontSize: "1rem",
                              textAlign: "right",
                            },
                            [theme.breakpoints.only("sm")]: {
                              fontSize: "1rem",
                              textAlign: "right",
                            },
                            [theme.breakpoints.only("xs")]: {
                              fontSize: "1rem",
                              textAlign: "right",
                            },
                          }}
                        >
                          คะแนน
                        </Typography>
                      </Grid>
                    </>
                  ))
                : null}

              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    textAlign: "left",
                    color: "#E42644",
                    mx: 1,
                    mt: 1,
                    [theme.breakpoints.down("md")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                  }}
                >
                  คะแนนใกล้หมดอายุ
                </Typography>
              </Grid>
              <Grid item lg={3} md={3} sm={3} xs={3}>
                <Typography
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    textAlign: "right",
                    color: "#E42644",
                    mx: 1,
                    mt: 1,
                    [theme.breakpoints.down("md")]: {
                      fontSize: "1rem",
                      textAlign: "right",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "1rem",
                      textAlign: "right",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "1rem",
                      textAlign: "right",
                    },
                  }}
                >
                  {!!expirePoint ? expirePoint.point : "0"}
                </Typography>
              </Grid>

              <Grid item lg={3} md={3} sm={3} xs={3}>
                <Typography
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    textAlign: "right",
                    color: "#E42644",
                    mx: 1,
                    mt: 1,
                    [theme.breakpoints.down("md")]: {
                      fontSize: "1rem",
                      textAlign: "right",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "1rem",
                      textAlign: "right",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "1rem",
                      textAlign: "right",
                    },
                  }}
                >
                  คะแนน
                </Typography>
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    textAlign: "left",
                    color: "#BCBEC0",
                    mx: 1,
                    mt: 1,
                    mb: 3,
                    [theme.breakpoints.down("md")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                  }}
                >
                  Expire Date :
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    textAlign: "right",
                    color: "#BCBEC0",
                    mx: 1,
                    mt: 1,
                    mb: 3,
                    [theme.breakpoints.down("md")]: {
                      fontSize: "1rem",
                      textAlign: "right",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "1rem",
                      textAlign: "right",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "1rem",
                      textAlign: "right",
                    },
                  }}
                >
                  {!!expirePoint ? expirePoint.date : "0"}
                </Typography>
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    textAlign: "left",
                    color: "#FFA500",
                    mx: 1,
                    mt: -2,
                    [theme.breakpoints.down("md")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                  }}
                >
                  คะแนนสะสม (รอชำระ)
                </Typography>
              </Grid>
              <Grid item lg={3} md={3} sm={3} xs={3}>
                <Typography
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    textAlign: "right",
                    color: "#FFA500",
                    mx: 1,
                    mt: -2,
                    [theme.breakpoints.down("md")]: {
                      fontSize: "1rem",
                      textAlign: "right",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "1rem",
                      textAlign: "right",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "1rem",
                      textAlign: "right",
                    },
                  }}
                >
                  {dataAll.collectPoint}
                </Typography>
              </Grid>
              <Grid item lg={3} md={3} sm={3} xs={3}>
                <Typography
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    textAlign: "right",
                    color: "#FFA500",
                    mx: 1,
                    mt: -2,
                    mb: 4,
                    [theme.breakpoints.down("md")]: {
                      fontSize: "1rem",
                      textAlign: "right",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "1rem",
                      textAlign: "right",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "1rem",
                      textAlign: "right",
                    },
                  }}
                >
                  คะแนน
                </Typography>
              </Grid>
            </Grid>
          </Box>

          {/* contract */}
          {!!contract
            ? contract.map((data, index) => (
                <Box
                  sx={{
                    width: "100%",
                    borderRadius: 1,
                    border: 0.1,
                    borderColor: "error.main",
                    mb: 1,
                    mt: 1,
                  }}
                  key={index}
                >
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "left",
                          mx: 1,
                          mt: 1,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                        }}
                      >
                        สัญญา (Contract)
                      </Typography>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "right",
                          mx: 1,
                          mt: 1,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                        }}
                      >
                        {data.contract}
                      </Typography>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "right",
                          mx: 1,
                          mt: 1,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                        }}
                      >
                        คะแนน
                      </Typography>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "left",
                          color: "#BCBEC0",
                          mx: 1,
                          mt: 1,
                          mb: 2,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                        }}
                      >
                        คะแนนสะสมแล้ว
                      </Typography>
                    </Grid>

                    <Grid item lg={3} md={3} sm={3} xs={3}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "right",
                          color: "#BCBEC0",
                          mx: 1,
                          mt: 1,

                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                        }}
                      >
                        {!!data.collectPoint ? data.collectPoint : null}
                      </Typography>
                    </Grid>

                    <Grid item lg={3} md={3} sm={3} xs={3}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "right",
                          color: "#BCBEC0",
                          mx: 1,
                          mt: 1,

                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                        }}
                      >
                        คะแนน
                      </Typography>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "left",
                          color: "#BCBEC0",
                          mx: 1,
                          mb: 3,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                        }}
                      >
                        คะแนนต้องสะสมเพิ่ม
                      </Typography>
                    </Grid>

                    <Grid item lg={3} md={3} sm={3} xs={3}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "right",
                          color: "#BCBEC0",
                          mx: 1,
                          mb: 3,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                        }}
                      >
                        {data.collectMore}
                      </Typography>
                    </Grid>

                    <Grid item lg={3} md={3} sm={3} xs={3}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "right",
                          color: "#BCBEC0",
                          mx: 1,
                          mb: 3,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                        }}
                      >
                        คะแนน
                      </Typography>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "left",
                          color: "#BCBEC0",
                          mb: 2,
                          mx: 1,
                          mt: -2,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                        }}
                      >
                        Due Date :
                      </Typography>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "right",
                          color: "#BCBEC0",
                          mx: 1,
                          mb: 2,
                          mt: -2,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                        }}
                      >
                        {data.date}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              ))
            : null}
        </Grid>
      </Grid>
    </>
  );
}
