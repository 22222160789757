import {
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { theme } from "./ui/theme";
import DeleteIcon from "@mui/icons-material/Delete";
import AppsIcon from "@mui/icons-material/Apps";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV !== "production"
      ? `${process.env.REACT_APP_BASE_URL_DEV}`
      : `${process.env.REACT_APP_BASE_URL_PROD}`,
});
export default function TableUpdateRichMenu(props) {
  const { dataAll } = props;
  const [myArray, setMyArray] = useState([]);

  const fetchData = async (value, value2) => {
    const header = {
      headers: {
        Authorization:
          "Bearer SBgEa7TTop79lU9uoKGW+PUAOxaifBEi9sFj3rZnoK2EnIz/84jFvNZMVLeFZcuAvI6jANZvAgfVfh8No00ReIJk0nIzugYooM3uO3ujvIbx+ta2PsEwgZylBN4YVUHDC5pgihgbWWIK13aH/XMzbAdB04t89/1O/w1cDnyilFU=",
        "Content-Type": "application/json",
      },
    };

    const sendData = {
      type: value,
    };
    // console.log(sendData);

    const res = await instance.get("/register/user", { params: sendData });
    // console.log(res.data.length);

    if (res.data.status !== false) {
      if (res.data.length < 501) {
        for (let index = 0; index < res.data.length; index++) {
          setMyArray([]);
          myArray.push(res.data[index].userId);
        }

        const sendData2 = {
          richMenuId: value2,
          userIds: myArray,
        };

        const res2 = await axios.post(
          "https://api.line.me/v2/bot/richmenu/bulk/link",
          sendData2,
          header
        );

        console.log(res2.data);
      } else if (res.data.length > 500) {
      }
    }
  };

  return (
    <div>
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ ...theme.container, mt: 4 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  rowSpan={2}
                  align="left"
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    background: "#8FC1EB",
                    mt: 10,
                    [theme.breakpoints.down("md")]: {
                      fontSize: "0.7rem",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "0.7rem",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "0.7rem",
                    },
                  }}
                >
                  Rich Menu ID
                </TableCell>
                <TableCell
                  rowSpan={2}
                  align="center"
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    background: "#8FC1EB",
                    [theme.breakpoints.down("md")]: {
                      fontSize: "0.7rem",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "0.7rem",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "0.7rem",
                    },
                  }}
                >
                  Alias Rich Menu
                </TableCell>
                <TableCell
                  rowSpan={2}
                  align="center"
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    background: "#8FC1EB",
                    [theme.breakpoints.down("md")]: {
                      fontSize: "0.7rem",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "0.7rem",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "0.7rem",
                    },
                  }}
                >
                  Type for
                </TableCell>
                <TableCell
                  rowSpan={2}
                  align="center"
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    background: "#8FC1EB",
                    [theme.breakpoints.down("md")]: {
                      fontSize: "0.7rem",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "0.7rem",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "0.7rem",
                    },
                  }}
                >
                  Rich Menu Name
                </TableCell>
                <TableCell
                  rowSpan={2}
                  align="center"
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    background: "#8FC1EB",
                    [theme.breakpoints.down("md")]: {
                      fontSize: "0.7rem",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "0.7rem",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "0.7rem",
                    },
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!!dataAll
                ? dataAll.map((data, index) => (
                    <TableRow>
                      <TableCell
                        align="left"
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "0.7rem",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "0.7rem",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "0.7rem",
                          },
                        }}
                      >
                        {!!data.richMenuID ? data.richMenuID : "-"}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "0.7rem",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "0.7rem",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "0.7rem",
                          },
                        }}
                      >
                        {!!data.AliasID ? data.AliasID : "-"}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "0.7rem",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "0.7rem",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "0.7rem",
                          },
                        }}
                      >
                        {!!data.userType ? data.userType : "-"}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "0.7rem",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "0.7rem",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "0.7rem",
                          },
                        }}
                      >
                        {!!data.richMenuName ? data.richMenuName : "-"}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "0.7rem",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "0.7rem",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "0.7rem",
                          },
                        }}
                      >
                        <Tooltip title="Broadcast">
                          <IconButton>
                            <AppsIcon
                              onClick={() => {
                                fetchData(data.userType, data.richMenuID);
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                : []}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
