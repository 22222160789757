import { Box, useMediaQuery, Typography, Grid, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { theme } from "./ui/theme";

export default function CustCreditStatus(props) {
  const match = useMediaQuery(theme.breakpoints.down("md"));
  const { dataAll } = props;
  // console.log("props", dataAll);
  return (
    <>
      <Grid container spacing={2}>
        {match ? null : (
          <Grid
            item
            md
            lg={3}
            sm={4}
            xs={6}
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          ></Grid>
        )}

        <Grid
          item
          md={12}
          lg={6}
          sm={8}
          xs={12}
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box sx={{ width: "100%" }}>
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    textAlign: "left",
                    mx: 1,
                    [theme.breakpoints.down("md")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                  }}
                >
                  วงเงินทั้งหมด
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    textAlign: "right",
                    mx: 1,
                    [theme.breakpoints.down("md")]: {
                      fontSize: "1rem",
                      textAlign: "right",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "1rem",
                      textAlign: "right",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "1rem",
                      textAlign: "right",
                    },
                  }}
                >
                  {!!dataAll && !!dataAll.AMTCRLIMT
                    ? dataAll.AMTCRLIMT.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "0"}
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ mb: 2, mt: 2 }} />
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    textAlign: "left",
                    mx: 1,
                    [theme.breakpoints.down("md")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                  }}
                >
                  วงเงินที่ใช้ไป
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    textAlign: "right",
                    mx: 1,
                    [theme.breakpoints.down("md")]: {
                      fontSize: "1rem",
                      textAlign: "right",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "1rem",
                      textAlign: "right",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "1rem",
                      textAlign: "right",
                    },
                  }}
                >
                  {!!dataAll && !!dataAll.AMTUSED
                    ? dataAll.AMTUSED.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "0"}
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ mb: 2, mt: 2 }} />
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    textAlign: "left",
                    mx: 1,
                    [theme.breakpoints.down("md")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                  }}
                >
                  วงเงินคงเหลือ
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    textAlign: "right",
                    mx: 1,
                    [theme.breakpoints.down("md")]: {
                      fontSize: "1rem",
                      textAlign: "right",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "1rem",
                      textAlign: "right",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "1rem",
                      textAlign: "right",
                    },
                  }}
                >
                  {!!dataAll && !!dataAll.AMTBALDUET
                    ? dataAll.AMTBALDUET.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "0"}
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ mb: 2, mt: 2 }} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
