import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  TextField,
  Typography,
  Button,
  styled,
  AppBar,
  Toolbar,
  useMediaQuery,
  MenuItem,
  Modal,
  Snackbar,
  Alert,
  Grid,
} from "@mui/material";
import { theme } from "../component/ui/theme";
import logoOranoss from "../asset/Logo-Oranoss.png";
import axios from "axios";
import TableUpdateRichMenu from "../component/TableUpdateRichMenu";

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV !== "production"
      ? `${process.env.REACT_APP_BASE_URL_DEV}`
      : `${process.env.REACT_APP_BASE_URL_PROD}`,
});

const InputBox = styled(TextField)(({ theme }) => ({
  width: "33%",
  marginTop: 16,
  [theme.breakpoints.down("lg")]: { width: "100%", marginTop: 8 },
}));

const richMenuType = [
  { type: "nonmember", id: 1 },
  { type: "member", id: 2 },
  { type: "dealer", id: 3 },
  { type: "employee", id: 4 },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  borderRadius: 3,
  // boxShadow: 24,
  p: 4,
};

export default function UpdateRichMenu() {
  const match = useMediaQuery(theme.breakpoints.down("md"));
  const [userType, setUserType] = useState("nonmember");
  const [richMenuObj, setRichMenuObj] = useState({
    richID: "",
    aliasID: "",
    richName: "",
  });
  const { richID, aliasID, richName } = richMenuObj;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleUpdateRichObj = (e) => {
    setRichMenuObj({ ...richMenuObj, [e.target.name]: e.target.value });
  };

  const [dataTables, setdataTables] = useState();

  const updateRiceMenu = async () => {
    const sendData = {
      richID: richID,
      aliasID: aliasID,
      richName: richName,
      userType: userType,
    };
    const res = await instance.post("/register/updateRichMenu", sendData);
    if (res.data.data) {
      setRichMenuObj({
        richID: "",
        aliasID: "",
        richName: "",
      });
      handleOpen(true);
    }
  };

  const dataRichmenu = async () => {
    const res = await instance.get("/register/getRichMenu");
    console.log(res.data);
    setdataTables(res.data.data);
  };

  useEffect(() => {
    dataRichmenu();
  }, []);

  return (
    <Box sx={{ backgroundColor: "#E1E1E1" }}>
      {match ? null : (
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" elevation={0}>
            <Toolbar sm={12} xs={12} sx={{ background: "#8FC1EB" }}>
              <Box
                component="img"
                alt="logo"
                src={logoOranoss}
                sx={{ width: 30, mr: 1 }}
              />
              <Typography sx={{ fontFamily: theme.typography, fontSize: 16 }}>
                Oranoss Co.,LTD.
              </Typography>
            </Toolbar>
          </AppBar>
        </Box>
      )}
      <Container maxWidth="xl" sx={{ bgcolor: "white", height: "100vh" }}>
        <Box sx={{ pt: 2 }}>
          <Typography variant="h6">Update Rich Menu</Typography>
        </Box>
        <Box sx={{ mt: 2, display: "flex", justifyContent: "end" }}>
          <TextField
            select
            size="small"
            label="RichMenuType"
            value={userType}
            onChange={(e) => setUserType(e.target.value)}
            helperText="please select rich menu"
          >
            {richMenuType.map((item) => (
              <MenuItem key={item.type} value={item.type}>
                {item.type}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box
          sx={{
            display: { lg: "flex", xs: "block" },
            justifyContent: "space-between",
          }}
        >
          <InputBox
            label="rich menu id"
            size="small"
            name="richID"
            value={richMenuObj.richID}
            onChange={handleUpdateRichObj}
          />
          <InputBox
            label="alias id"
            size="small"
            name="aliasID"
            value={richMenuObj.aliasID}
            onChange={handleUpdateRichObj}
          />
          <InputBox
            label="rich menu name"
            size="small"
            name="richName"
            value={richMenuObj.richName}
            onChange={handleUpdateRichObj}
          />
        </Box>
        <Box sx={{ mt: 1, textAlign: "right" }}>
          <Button
            disabled={!richID || !aliasID || !richName}
            variant="outlined"
            color="primary"
            onClick={() => updateRiceMenu()}
          >
            update
          </Button>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <Grid item md={12} sm={12} lg={12} xs={12}>
            <TableUpdateRichMenu dataAll={dataTables} />
          </Grid>
        </Box>
      </Container>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            align="center"
            sx={{ color: theme.palette.success.main }}
          >
            update rich menu complete
          </Typography>
        </Box>
      </Modal>

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          update rich menu complete
        </Alert>
      </Snackbar>
    </Box>
  );
}
