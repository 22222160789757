import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: { main: "#0451A5" },
    secondary: { main: "#9c27b0" },
    success: { main: "#2e7d32" },
    error: { main: "#d32f2f" },
    warning: { main: "#ed6c02" },
    info: { main: "#0288d1" },
    
  },
  typography: {
    fontFamily: "Prompt",
    registerLabel: {
      fontFamily: "Prompt",
    },
    massageRegis: {
      fontFamily: "Roboto",
      color: "white",
    },
  },
  container: {
    maxHeight: "58vh",
    overflow: "auto",
  },
});
