import {
  Box,
  useMediaQuery,
  AppBar,
  Toolbar,
  Typography,
  Grid,
  Container,
  Button,
} from "@mui/material";
import React, { Suspense, useEffect, useState } from "react";
import axios from "axios";
import logoOranoss from "../asset/Logo-Oranoss.png";
import { theme } from "../component/ui/theme";
import ProFileCom from "../component/ProFileCom";
import { getProfile } from "../service/getProfileFromLine";
import { useSearchParams } from "react-router-dom";
import liff from "@line/liff";

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV !== "production"
      ? `${process.env.REACT_APP_BASE_URL_DEV}`
      : `${process.env.REACT_APP_BASE_URL_PROD}`,
});

export default function Profile() {
  const match = useMediaQuery(theme.breakpoints.down("md"));
  const [allData, setAllData] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState(searchParams.get("uId"));

  useEffect(() => {
    // liff.init({ liffId: `${process.env.REACT_APP_LIFF_ID}` }, async () => {
    //   if (liff.isLoggedIn()) {
    //     const profile = await liff.getProfile();
    //     const userId = profile.userId;
    //     const getProfileCust = async () => {
    //       const res = await instance.post("/register/profile", {
    //         userId: userId,
    //         // modularId: query,
    //         // modularId: "LUe36534d6a19bf6b1b961d12541ea6c7d-U2dcacfde57f92974aa72fccc3876cd41",
    //       });
    //       // console.log(res.data);
    //       if (res.data.status !== false) {
    //         setAllData(res.data);
    //       }
    //     };
    //     getProfileCust();
    //   } else {
    //     liff.login({ redirectUri: window.location.href });
    //   }
    // });
  }, []);

  return (
    <Box sx={{ backgroundColor: "#E1E1E1" }}>
      {match ? null : (
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" elevation={0}>
            <Toolbar sm={12} xs={12} sx={{ background: "#8FC1EB" }}>
              <Box
                component="img"
                alt="logo"
                src={logoOranoss}
                sx={{ width: 30, mr: 1 }}
              />
              <Typography sx={{ fontFamily: theme.typography, fontSize: 16 }}>
                Oranoss Co.,LTD.
              </Typography>
            </Toolbar>
          </AppBar>
        </Box>
      )}

      <Container sx={{ bgcolor: "white", height: "100vh" }}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: "30%",

              [theme.breakpoints.down("md")]: {
                width: "60%",
              },
              [theme.breakpoints.only("sm")]: {
                width: "50%",
              },
              [theme.breakpoints.only("xs")]: {
                width: "60%",
              },
            }}
          >
            <Typography
              sx={{
                ...theme.typography.registerLabel,
                fontSize: 20,
                fontWeight: "700",
                mt: 4,
                mb: 4,
                textAlign: "center",
                bgcolor: "red",
                color: "white",
                borderRadius: 10,
                p: 1,

                [theme.breakpoints.down("md")]: {
                  fontSize: "1rem",
                  fontWeight: "700",
                  p: 1,
                },
                [theme.breakpoints.only("sm")]: {
                  fontSize: "1rem",
                  fontWeight: "700",
                  p: 1,
                },
                [theme.breakpoints.only("xs")]: {
                  fontSize: "1rem",
                  fontWeight: "700",
                  p: 1,
                },
              }}
            >
              ข้อมูลลูกค้า
            </Typography>
          </Box>
        </Grid>

        <Grid item md={12} sm={12} lg={12} xs={12}>
          <ProFileCom dataAll={allData} />
        </Grid>
      </Container>
    </Box>
  );
}
