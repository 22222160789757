import {
  Box,
  useMediaQuery,
  Typography,
  Grid,
  Divider,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { theme } from "./ui/theme";

export default function ProFileCom(props) {
  const { dataAll } = props;
  // console.log("props : ", dataAll);
  const editEmail = () => alert("test");

  const match = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Grid container spacing={2} sx={{ height: "100vh" }}>
        {match ? null : (
          <Grid
            item
            md
            lg={3}
            sm={4}
            xs={6}
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          ></Grid>
        )}

        <Grid
          item
          md={12}
          lg={6}
          sm={8}
          xs={12}
          container
          // direction="column"
          // alignItems="center"
          justifyContent="center"
        >
          <Box sx={{ width: "100%" }}>
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={2}>
              <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
              <Grid item lg={3} md={3} sm={3} xs={3}>
                <Typography
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    textAlign: "left",
                    color: "#BCBEC0",

                    [theme.breakpoints.down("md")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                  }}
                >
                  รหัสลูกค้า
                </Typography>
              </Grid>
              <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
              <Grid item lg={7} md={7} sm={7} xs={7}>
                <Typography
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    textAlign: "left",
                    mx: 1,
                    [theme.breakpoints.down("md")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                  }}
                >
                  {!!dataAll && dataAll.data[0].CUSTID
                    ? dataAll.data[0].CUSTID
                    : "-"}
                </Typography>
              </Grid>
              <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
              <Grid item lg={3} md={3} sm={3} xs={3}>
                <Typography
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    textAlign: "left",

                    color: "#BCBEC0",
                    [theme.breakpoints.down("md")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                  }}
                >
                  ชื่อบริษัท
                </Typography>
              </Grid>
              <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
              <Grid item lg={7} md={7} sm={7} xs={7}>
                <Typography
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    textAlign: "left",
                    mx: 1,
                    [theme.breakpoints.down("md")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                  }}
                >
                  {!!dataAll && dataAll.data[0].CUSTNAME
                    ? dataAll.data[0].CUSTNAME
                    : "-"}
                </Typography>
              </Grid>

              <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
              <Grid item lg={3} md={3} sm={3} xs={3}>
                <Typography
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    textAlign: "left",

                    color: "#BCBEC0",
                    [theme.breakpoints.down("md")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                  }}
                >
                  ที่อยู่
                </Typography>
              </Grid>
              <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
              <Grid item lg={7} md={7} sm={7} xs={7}>
                <Typography
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    textAlign: "left",
                    mx: 1,
                    [theme.breakpoints.down("md")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                  }}
                >
                  {!!dataAll && dataAll.data[0].ADDRESS
                    ? dataAll.data[0].ADDRESS
                    : "-"}
                </Typography>
              </Grid>
              <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
              <Grid item lg={3} md={3} sm={3} xs={3}>
                <Typography
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    textAlign: "left",
                    color: "#BCBEC0",
                    [theme.breakpoints.down("md")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                  }}
                >
                  ติดต่อ
                </Typography>
              </Grid>
              <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
              <Grid item lg={7} md={7} sm={7} xs={7}>
                <Typography
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    textAlign: "left",
                    mx: 1,
                    [theme.breakpoints.down("md")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                  }}
                >
                  {!!dataAll && dataAll.data[0].CONTCNAME
                    ? dataAll.data[0].CONTCNAME
                    : "-"}
                </Typography>
              </Grid>

              <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
              <Grid item lg={3} md={3} sm={3} xs={3}>
                <Typography
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    textAlign: "left",

                    color: "#BCBEC0",
                    [theme.breakpoints.down("md")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                  }}
                >
                  เบอร์โทร
                </Typography>
              </Grid>
              <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
              <Grid item lg={7} md={7} sm={7} xs={7}>
                <Typography
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    textAlign: "left",
                    mx: 1,
                    [theme.breakpoints.down("md")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                  }}
                >
                  {!!dataAll && dataAll.data[0].MOBILE
                    ? dataAll.data[0].MOBILE
                    : "-"}
                </Typography>
              </Grid>

              <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
              <Grid item lg={3} md={3} sm={3} xs={3}>
                <Typography
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    textAlign: "left",

                    color: "#BCBEC0",
                    [theme.breakpoints.down("md")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                  }}
                >
                  Line ID
                </Typography>
              </Grid>
              <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
              <Grid item lg={7} md={7} sm={7} xs={7}>
                <Typography
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    textAlign: "left",
                    mx: 1,
                    [theme.breakpoints.down("md")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                  }}
                >
                  {!!dataAll && !!dataAll.displayName
                    ? dataAll.displayName
                    : "-"}
                </Typography>
              </Grid>

              <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
              <Grid item lg={3} md={3} sm={3} xs={3}>
                <Typography
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    textAlign: "left",

                    color: "#BCBEC0",
                    [theme.breakpoints.down("md")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                  }}
                >
                  ผู้ดูแล
                </Typography>
              </Grid>
              <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
              <Grid item lg={7} md={7} sm={7} xs={7}>
                <Typography
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    textAlign: "left",
                    mx: 1,
                    [theme.breakpoints.down("md")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                  }}
                >
                  {!!dataAll && !!dataAll.data[0].CONSULTANT
                    ? dataAll.data[0].CONSULTANT
                    : "-"}
                </Typography>
              </Grid>

              <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
              <Grid item lg={3} md={3} sm={3} xs={3}>
                <Typography
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    textAlign: "left",

                    color: "#BCBEC0",
                    [theme.breakpoints.down("md")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                  }}
                >
                  เบอร์โทร
                </Typography>
              </Grid>
              <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
              <Grid item lg={7} md={7} sm={7} xs={7}>
                <Typography
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    textAlign: "left",
                    mx: 1,
                    mb: 4,
                    [theme.breakpoints.down("md")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "1rem",
                      textAlign: "left",
                    },
                  }}
                >
                  {!!dataAll && !!dataAll.data[0].CONSMOBILE
                    ? dataAll.data[0].CONSMOBILE
                    : "-"}
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button variant="outlined" onClick={editEmail}>
                edit e-mail
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
