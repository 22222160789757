import {
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { theme } from "./ui/theme";

export default function TableProductDeposit(props) {
  const { dataAll } = props;

  return (
    <div>
      <TableContainer sx={{ ...theme.container }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                rowSpan={2}
                align="center"
                sx={{
                  ...theme.typography.registerLabel,
                  fontSize: 20,
                  background: "#EE7487",
                  color: "white",
                  [theme.breakpoints.down("md")]: {
                    fontSize: "0.7rem",
                  },
                  [theme.breakpoints.only("sm")]: {
                    fontSize: "0.7rem",
                  },
                  [theme.breakpoints.only("xs")]: {
                    fontSize: "0.7rem",
                  },
                }}
              >
                รายการ
              </TableCell>
              <TableCell
                rowSpan={2}
                align="center"
                sx={{
                  ...theme.typography.registerLabel,
                  fontSize: 20,
                  background: "#EE7487",
                  color: "white",
                  [theme.breakpoints.down("md")]: {
                    fontSize: "0.7rem",
                  },
                  [theme.breakpoints.only("sm")]: {
                    fontSize: "0.7rem",
                  },
                  [theme.breakpoints.only("xs")]: {
                    fontSize: "0.7rem",
                  },
                }}
              >
                จำนวน
              </TableCell>
              <TableCell
                rowSpan={2}
                align="center"
                sx={{
                  ...theme.typography.registerLabel,
                  fontSize: 20,
                  background: "#EE7487",
                  color: "white",
                  [theme.breakpoints.down("md")]: {
                    fontSize: "0.7rem",
                  },
                  [theme.breakpoints.only("sm")]: {
                    fontSize: "0.7rem",
                  },
                  [theme.breakpoints.only("xs")]: {
                    fontSize: "0.7rem",
                  },
                }}
              >
                หน่วย
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!!dataAll
              ? dataAll.map((data, index) => (
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{
                        ...theme.typography.registerLabel,
                        fontSize: 20,
                        [theme.breakpoints.down("md")]: {
                          fontSize: "0.7rem",
                        },
                        [theme.breakpoints.only("sm")]: {
                          fontSize: "0.7rem",
                        },
                        [theme.breakpoints.only("xs")]: {
                          fontSize: "0.7rem",
                        },
                      }}
                    >
                      {!!data.PRODNAMEONLINE ? data.PRODNAMEONLINE : "-"}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        ...theme.typography.registerLabel,
                        fontSize: 20,
                        [theme.breakpoints.down("md")]: {
                          fontSize: "0.7rem",
                        },
                        [theme.breakpoints.only("sm")]: {
                          fontSize: "0.7rem",
                        },
                        [theme.breakpoints.only("xs")]: {
                          fontSize: "0.7rem",
                        },
                      }}
                    >
                      {!!data.LineNum ? data.LineNum : "-"}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        ...theme.typography.registerLabel,
                        fontSize: 20,
                        [theme.breakpoints.down("md")]: {
                          fontSize: "0.7rem",
                        },
                        [theme.breakpoints.only("sm")]: {
                          fontSize: "0.7rem",
                        },
                        [theme.breakpoints.only("xs")]: {
                          fontSize: "0.7rem",
                        },
                      }}
                    >
                      {!!data.PACKAGE ? data.PACKAGE : "-"}
                    </TableCell>
                  </TableRow>
                ))
              : []}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
