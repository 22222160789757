import {
  Box,
  useMediaQuery,
  AppBar,
  Toolbar,
  Typography,
  Grid,
  Container,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import logoOranoss from "../asset/Logo-Oranoss.png";
import { theme } from "../component/ui/theme";
import TransportStatus from "../component/TransportStatus";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import TransportStatus2 from "../component/TransportStatus2";
import liff from '@line/liff'

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV !== "production"
      ? `${process.env.REACT_APP_BASE_URL_DEV}`
      : `${process.env.REACT_APP_BASE_URL_PROD}`,
});

export default function Transport() {
  const match = useMediaQuery(theme.breakpoints.down("md"));
  const [allData, setAllData] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState(searchParams.get("uId"));

  useEffect(() => {
    liff.init({ liffId: `${process.env.REACT_APP_LIFF_ID}` }, async () => {
      if (liff.isLoggedIn()) {
        const profile = await liff.getProfile();
        const userId = profile.userId;
        const getTransportStatus = async () => {
          const res = await instance.post("/register/transport-status", {
            userId: userId,
            // modularId: query,
          });
          // console.log(res.data.results[0]);
          setAllData(res.data.data);
        };

        getTransportStatus();
      } else {
        liff.login({ redirectUri: window.location.href });
      }
    });
  }, []);

  return (
    <>
      <Box sx={{ backgroundColor: "#E1E1E1" }}>
        {match ? null : (
          <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" elevation={0}>
              <Toolbar sm={12} xs={12} sx={{ background: "#8FC1EB" }}>
                <Box
                  component="img"
                  alt="logo"
                  src={logoOranoss}
                  sx={{ width: 30, mr: 1 }}
                />
                <Typography sx={{ fontFamily: theme.typography, fontSize: 16 }}>
                  Oranoss Co.,LTD.
                </Typography>
              </Toolbar>
            </AppBar>
          </Box>
        )}

        <Container sx={{ bgcolor: "white", height: "auto" }}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{
                width: "30%",
                [theme.breakpoints.down("md")]: {
                  width: "60%",
                },
                [theme.breakpoints.only("sm")]: {
                  width: "50%",
                },
                [theme.breakpoints.only("xs")]: {
                  width: "60%",
                },
              }}
            >
              <Typography
                sx={{
                  ...theme.typography.registerLabel,
                  fontSize: 20,
                  fontWeight: "700",
                  mt: 4,
                  textAlign: "center",
                  bgcolor: "red",
                  color: "white",
                  borderRadius: 10,
                  p: 1,

                  [theme.breakpoints.down("md")]: {
                    fontSize: "1rem",
                    fontWeight: "700",
                    p: 1,
                  },
                  [theme.breakpoints.only("sm")]: {
                    fontSize: "1rem",
                    fontWeight: "700",
                    p: 1,
                  },
                  [theme.breakpoints.only("xs")]: {
                    fontSize: "1rem",
                    fontWeight: "700",
                    p: 1,
                  },
                }}
              >
                สถานะการส่งสินค้า
              </Typography>
            </Box>
          </Grid>

          <Grid item md={12} sm={12} lg={12} xs={12}>
            {!!allData
              ? allData.map((data, index) =>
                  data.TransportStatus === "เสร็จแล้ว" ? (
                    <TransportStatus dataAll={allData} uId={query} />
                  ) : (
                    <TransportStatus2 dataAll={allData} uId={query} />
                  )
                )
              : null}
          </Grid>
        </Container>
      </Box>
    </>
  );
}
