import { Box, useMediaQuery, Typography, Grid, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { theme } from "./ui/theme";
import { format, parseISO } from "date-fns";
import { th } from "date-fns/locale";

export default function SaleHistoryData(props) {
  const match = useMediaQuery(theme.breakpoints.down("md"));
  const { dataAll } = props;

  console.log("dataAll", dataAll);
  // console.log("TablesAll", dataTables);
  return (
    <>
      <Grid container spacing={2}>
        {match ? null : (
          <Grid
            item
            md
            lg={3}
            sm={4}
            xs={6}
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          ></Grid>
        )}

        <Grid
          item
          md={12}
          lg={6}
          sm={8}
          xs={12}
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          {!!dataAll
            ? dataAll.map((data, index) => (
                <Box
                  sx={{
                    width: "100%",
                    borderRadius: 1,
                    border: 0.1,
                    borderColor: "error.main",
                    mb: 1,
                    mt: 1,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          fontWeight: "700",
                          mt: 4,
                          mb: 4,
                          textAlign: "center",

                          color: "red",

                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            fontWeight: "700",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            fontWeight: "700",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            fontWeight: "700",
                          },
                        }}
                      >
                        {!!data && !!data.INVNO ? data.INVNO : "-"} /{" "}
                        {!!data && !!data.SHIPNO ? data.SHIPNO : "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ mb: 2 }} />
                  <Grid container spacing={2}>
                    <Grid item lg={7} md={7} sm={7} xs={7}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "left",
                          mx: 1,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                        }}
                      >
                        วันที่{" "}
                        {!!data && !!data.INVDATE
                          ? format(parseISO(data.INVDATE), "dd-MM-yyyy")
                          : "-"}
                      </Typography>
                    </Grid>
                    <Grid item lg={5} md={5} sm={5} xs={5}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "right",
                          mx: 1,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                        }}
                      >
                        {!!data && !!data.STEP ? data.STEP : "-"}
                      </Typography>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={4}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          mx: 0.5,
                          textAlign: "left",
                          [theme.breakpoints.down("md")]: {
                            fontSize: "0.7rem",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "0.7rem",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "0.7rem",
                          },
                        }}
                      >
                        จำนวน (หน่วย)
                      </Typography>
                    </Grid>

                    <Grid item lg={4} md={4} sm={4} xs={4}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "center",

                          [theme.breakpoints.down("md")]: {
                            fontSize: "0.7rem",
                            textAlign: "center",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "0.7rem",
                            textAlign: "center",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "0.7rem",
                            textAlign: "center",
                          },
                        }}
                      >
                        รายการ
                      </Typography>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={4}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "right",
                          mx: 1,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "0.7rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "0.7rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "0.7rem",
                            textAlign: "right",
                          },
                        }}
                      >
                        ราคา (บาท)
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ mb: 2, mt: 2 }} />
                  {!!data.line
                    ? data.line.map((data, index2) => (
                        <Grid container spacing={1}>
                          <Grid item lg={3} md={3} sm={3} xs={3}>
                            <Typography
                              sx={{
                                ...theme.typography.registerLabel,
                                fontSize: 20,
                                textAlign: "left",
                                mx: 1,
                                [theme.breakpoints.down("md")]: {
                                  fontSize: "0.7rem",
                                  textAlign: "left",
                                },
                                [theme.breakpoints.only("sm")]: {
                                  fontSize: "0.7rem",
                                  textAlign: "left",
                                },
                                [theme.breakpoints.only("xs")]: {
                                  fontSize: "0.7rem",
                                  textAlign: "left",
                                },
                              }}
                            >
                              {!!data && !!data.PACKQTY ? data.PACKQTY : "-"}{" "}
                              {!!data && !!data.PACKAGE ? data.PACKAGE : "-"}
                            </Typography>
                          </Grid>

                          <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Typography
                              sx={{
                                ...theme.typography.registerLabel,
                                fontSize: 20,
                                textAlign: "left",
                                mx: 1,

                                [theme.breakpoints.down("md")]: {
                                  fontSize: "0.7rem",
                                  textAlign: "left",
                                },
                                [theme.breakpoints.only("sm")]: {
                                  fontSize: "0.7rem",
                                  textAlign: "left",
                                },
                                [theme.breakpoints.only("xs")]: {
                                  fontSize: "0.7rem",
                                  textAlign: "left",
                                },
                              }}
                            >
                              {!!data && !!data.PRODNAMEONLINE
                                ? data.PRODNAMEONLINE
                                : "-"}
                            </Typography>
                          </Grid>
                          <Grid item lg={3} md={3} sm={3} xs={3}>
                            <Typography
                              sx={{
                                ...theme.typography.registerLabel,
                                fontSize: 20,
                                textAlign: "right",
                                mx: 1,

                                [theme.breakpoints.down("md")]: {
                                  fontSize: "0.7rem",
                                  textAlign: "right",
                                },
                                [theme.breakpoints.only("sm")]: {
                                  fontSize: "0.7rem",
                                  textAlign: "right",
                                },
                                [theme.breakpoints.only("xs")]: {
                                  fontSize: "0.7rem",
                                  textAlign: "right",
                                },
                              }}
                            >
                              {!!data && !!data.INVAMT
                                ? data.INVAMT.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : "0.00"}
                            </Typography>
                          </Grid>
                        </Grid>
                      ))
                    : []}
                  <Divider sx={{ mt: 2, mb: 2 }} />

                  <Grid container spacing={2}>
                    <Grid item lg={8} md={8} sm={8} xs={8}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "right",
                          color: "#6F7071",
                          mx: 1,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "0.7rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "0.7rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "0.7rem",
                            textAlign: "right",
                          },
                        }}
                      >
                        รวมก่อนส่วนลด
                      </Typography>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={4}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "right",
                          mx: 1,
                          color: "#6F7071",
                          [theme.breakpoints.down("md")]: {
                            fontSize: "0.7rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "0.7rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "0.7rem",
                            textAlign: "right",
                          },
                        }}
                      >
                        {!!data && !!data.INVAMT
                          ? data.INVAMT.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : "-"}
                      </Typography>
                    </Grid>
                    <Grid item lg={8} md={8} sm={8} xs={8}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "right",
                          mx: 1,
                          mt: -2,
                          color: "#6F7071",
                          [theme.breakpoints.down("md")]: {
                            fontSize: "0.7rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "0.7rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "0.7rem",
                            textAlign: "right",
                          },
                        }}
                      >
                        ส่วนลด
                      </Typography>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={4}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "right",
                          mx: 1,
                          mt: -2,
                          color: "#6F7071",
                          [theme.breakpoints.down("md")]: {
                            fontSize: "0.7rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "0.7rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "0.7rem",
                            textAlign: "right",
                          },
                        }}
                      >
                        {!!data && !!data.INVDISTC
                          ? data.INVDISTC.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : "0.00"}
                      </Typography>
                    </Grid>
                    <Grid item lg={8} md={8} sm={8} xs={8}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "right",
                          mx: 1,
                          mt: -2,
                          color: "#6F7071",
                          [theme.breakpoints.down("md")]: {
                            fontSize: "0.7rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "0.7rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "0.7rem",
                            textAlign: "right",
                          },
                        }}
                      >
                        Vat
                      </Typography>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={4}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "right",
                          mx: 1,
                          mt: -2,
                          color: "#6F7071",
                          [theme.breakpoints.down("md")]: {
                            fontSize: "0.7rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "0.7rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "0.7rem",
                            textAlign: "right",
                          },
                        }}
                      >
                        {!!data && !!data.INVNETTAX
                          ? data.INVNETTAX.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : "-"}
                      </Typography>
                    </Grid>
                    <Grid item lg={8} md={8} sm={8} xs={8}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "right",
                          mx: 1,
                          mt: -2,
                          color: "#6F7071",
                          [theme.breakpoints.down("md")]: {
                            fontSize: "0.7rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "0.7rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "0.7rem",
                            textAlign: "right",
                          },
                        }}
                      >
                        รวมค่าสุทธิ
                      </Typography>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={4}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "right",
                          mx: 1,
                          mt: -2,
                          mb: 2,
                          color: "#6F7071",
                          [theme.breakpoints.down("md")]: {
                            fontSize: "0.7rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "0.7rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "0.7rem",
                            textAlign: "right",
                          },
                        }}
                      >
                        {!!data && !!data.INVAMT
                          ? (
                              data.INVAMT -
                              data.INVDISTC +
                              data.INVNETTAX
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              ))
            : []}
        </Grid>
      </Grid>
    </>
  );
}
