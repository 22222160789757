import {
  Box,
  Pagination,
  useMediaQuery,
  Typography,
  Grid,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { theme } from "./ui/theme";
import TableProductDeposit from "./TableProductDeposit";
import Paper from "@mui/material/Paper";
import { format, parseISO } from "date-fns";

export default function ProductDepositStatus(props) {
  const match = useMediaQuery(theme.breakpoints.down("md"));
  const { dataAll, dataNum } = props;
  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };

  // console.log(dataAll);
  return (
    <>
      <Grid container spacing={2}>
        {match ? null : (
          <Grid
            item
            md
            lg={3}
            sm={4}
            xs={6}
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          ></Grid>
        )}

        <Grid
          item
          md={12}
          lg={6}
          sm={8}
          xs={12}
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    textAlign: "left",

                    [theme.breakpoints.down("md")]: {
                      fontSize: "0.7rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "0.7rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "0.7rem",
                      textAlign: "left",
                    },
                  }}
                >
                  เลขที่เอกสาร :{" "}
                  {!!dataAll && !!dataAll[page - 1].TRID
                    ? dataAll[page - 1].TRID
                    : "-"}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    textAlign: "right",

                    [theme.breakpoints.down("md")]: {
                      fontSize: "0.7rem",
                      textAlign: "right",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "0.7rem",
                      textAlign: "right",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "0.7rem",
                      textAlign: "right",
                    },
                  }}
                >
                  วันที่จัดส่ง :{" "}
                  {!!dataAll && !!dataAll[page - 1].TRDate
                    ? format(parseISO(dataAll[page - 1].TRDate), "dd-MM-yyyy")
                    : "-"}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    textAlign: "left",

                    [theme.breakpoints.down("md")]: {
                      fontSize: "0.7rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "0.7rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "0.7rem",
                      textAlign: "left",
                    },
                  }}
                >
                  เลขที่เอกสาร :{" "}
                  {!!dataAll && !!dataAll[page - 1].SHIPNO
                    ? dataAll[page - 1].SHIPNO
                    : "-"}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    textAlign: "right",

                    [theme.breakpoints.down("md")]: {
                      fontSize: "0.7rem",
                      textAlign: "right",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "0.7rem",
                      textAlign: "right",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "0.7rem",
                      textAlign: "right",
                    },
                  }}
                >
                  วันที่ฝาก :{" "}
                  {!!dataAll && !!dataAll[page - 1].SHIPDATE
                    ? format(parseISO(dataAll[page - 1].SHIPDATE), "dd-MM-yyyy")
                    : "-"}
                </Typography>
              </Grid>
              <Grid item lg={2} md={2} sm={2} xs={2}>
                <Typography
                  sx={{
                    ...theme.typography.registerLabel,
                    fontSize: 20,
                    textAlign: "left",

                    [theme.breakpoints.down("md")]: {
                      fontSize: "0.7rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("sm")]: {
                      fontSize: "0.7rem",
                      textAlign: "left",
                    },
                    [theme.breakpoints.only("xs")]: {
                      fontSize: "0.7rem",
                      textAlign: "left",
                    },
                  }}
                >
                  สถานะ :
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                {!!dataAll &&
                dataAll[page - 1].StepName.trim() === "ทยอยนำส่งสินค้า" ? (
                  <Typography
                    sx={{
                      ...theme.typography.registerLabel,
                      fontSize: 20,
                      color: "#EFB111",
                      [theme.breakpoints.down("md")]: {
                        fontSize: "0.7rem",
                      },
                      [theme.breakpoints.only("sm")]: {
                        fontSize: "0.7rem",
                      },
                      [theme.breakpoints.only("xs")]: {
                        fontSize: "0.7rem",
                      },
                    }}
                  >
                    {!!dataAll && !!dataAll[page - 1].StepName
                      ? dataAll[page - 1].StepName
                      : "-"}
                  </Typography>
                ) : !!dataAll &&
                  dataAll[page - 1].StepName.trim() === "ส่งสินค้าแล้ว" ? (
                  <Typography
                    sx={{
                      ...theme.typography.registerLabel,
                      fontSize: 20,
                      color: "green",
                      [theme.breakpoints.down("md")]: {
                        fontSize: "0.7rem",
                      },
                      [theme.breakpoints.only("sm")]: {
                        fontSize: "0.7rem",
                      },
                      [theme.breakpoints.only("xs")]: {
                        fontSize: "0.7rem",
                      },
                    }}
                  >
                    {!!dataAll && !!dataAll[page - 1].StepName
                      ? dataAll[page - 1].StepName
                      : "-"}
                  </Typography>
                ) : !!dataAll &&
                  dataAll[page - 1].StepName.trim() === "ฝากวาง" ? (
                  <Typography
                    sx={{
                      ...theme.typography.registerLabel,
                      fontSize: 20,
                      color: "red",
                      [theme.breakpoints.down("md")]: {
                        fontSize: "0.7rem",
                      },
                      [theme.breakpoints.only("sm")]: {
                        fontSize: "0.7rem",
                      },
                      [theme.breakpoints.only("xs")]: {
                        fontSize: "0.7rem",
                      },
                    }}
                  >
                    {!!dataAll && !!dataAll[page - 1].StepName
                      ? dataAll[page - 1].StepName
                      : "-"}
                  </Typography>
                ) : (
                  "-"
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Divider variant="middle" sx={{ m: 2 }} />
      <Grid container component={Paper}>
        <Grid item md={12} sm={12} lg={12} xs={12}>
          <TableProductDeposit
            dataAll={!!dataAll ? dataAll[page - 1].line : ""}
          />
        </Grid>
      </Grid>

      <Grid
        item
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        md={12}
        sm={12}
        lg={12}
        xs={12}
      >
        <Box sx={{ display: "flex", textAlign: "center" }}>
          <Pagination
            count={dataNum}
            page={page}
            color="primary"
            sx={{
              ...theme.typography.registerLabel,
              fontSize: 20,
              mt: 2,
            }}
            onChange={handleChange}
          />
        </Box>
      </Grid>
    </>
  );
}
