import {
  Box,
  useMediaQuery,
  Typography,
  Grid,
  Divider,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { th } from "date-fns/locale";
import { theme } from "./ui/theme";

export default function TransportStatus(props) {
  const match = useMediaQuery(theme.breakpoints.down("md"));
  const { dataAll, uId } = props;

  // console.log(dataAll);
  return (
    <>
      <Grid container spacing={2}>
        {match ? null : (
          <Grid
            item
            md
            lg={3}
            sm={4}
            xs={6}
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          ></Grid>
        )}

        <Grid
          item
          md={12}
          lg={6}
          sm={8}
          xs={12}
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          {!!dataAll
            ? dataAll.map((data, index) => (
                <Box
                  sx={{
                    width: "100%",
                    borderRadius: 1,
                    border: 0.1,
                    borderColor: "error.main",
                    mb: 1,
                    mt: 1,
                  }}
                  key={index}
                >
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          fontWeight: "700",
                          mt: 3,
                          mb: 2,
                          textAlign: "center",
                          p: 1,
                          color: "red",
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            fontWeight: "700",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            fontWeight: "700",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            fontWeight: "700",
                          },
                        }}
                      >
                        {`${data.INVNO} / ${data.SHIPNO}`}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ mb: 2 }} />
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "left",
                          mx: 1,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                        }}
                      >
                        วันสั่งซื้อ
                      </Typography>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "right",
                          mx: 1,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                        }}
                      >
                        {format(parseISO(data.SHIPDATE), "dd-MM-yyyy")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ mb: 2, mt: 2 }} />
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "left",
                          mx: 1,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                        }}
                      >
                        แพ็กสินค้า
                      </Typography>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "right",
                          mx: 1,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                        }}
                      >
                        {data.PickingStatus}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ mb: 2, mt: 2 }} />
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "left",
                          mx: 1,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                        }}
                      >
                        เตรียมส่งขนส่ง
                      </Typography>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "right",
                          mx: 1,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                        }}
                      >
                        {data.TransportStatus}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ mb: 2, mt: 2 }} />
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "left",
                          mx: 1,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                        }}
                      >
                        ขนส่งสินค้า
                      </Typography>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "right",
                          mx: 1,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                        }}
                      >
                        -
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ mt: 2, mb: 2 }} />
                  <Box sx={{ display: "flex", textAlign: "center" }}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography
                        component={Link}
                        to={`/SaleHistory?uId=${uId}`}
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 18,
                          color: "#2496F6",
                          textAlign: "center",
                          mx: 1,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "center",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "center",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "center",
                          },
                        }}
                      >
                        ดูรายละเอียด
                      </Typography>
                    </Grid>
                  </Box>
                </Box>
              ))
            : []}
        </Grid>
      </Grid>
    </>
  );
}
