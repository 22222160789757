import {
  Alert,
  Button,
  Grid,
  InputAdornment,
  Snackbar,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import backgroundRegister from "../asset/background register.svg";
import logoOranoss from "../asset/Logo-Oranoss.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PasswordIcon from "@mui/icons-material/Password";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import { theme } from "./ui/theme";
import { register } from "../service/register";
import Cookies from "js-cookie";
import liff from "@line/liff";

export default function Register() {
  const [searchParams, setSearchParams] = useSearchParams();
  const match = useMediaQuery(theme.breakpoints.down("md"));
  const [signIn, setSignIn] = useState(false);
  const [checkBTN, setCheckBTN] = useState(false);
  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    mobile: "",
    code: "",
    userId: "",
    email: "",
    displayName: "",
  });

  const { firstname, lastname, mobile, code, userId, email, displayName } =
    data;

  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: "",
    vertical: "top",
    horizontal: "center",
    message: "",
  });
  const { open, severity, vertical, horizontal, message } = snackbar;

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleChangeCopy = (e) => {
    e.preventDefault();
  };
  const handleSnackClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const sendRegister = async () => {
    setCheckBTN(true);

    // console.log(checkBTN);
    const sendData = {
      modularId: Cookies.get("modularId"),
      firstname: firstname,
      lastname: lastname,
      mobile: mobile,
      custId: code,
      email: email,
      userId: userId,
      displayName: displayName,
    };

    // const sendData = {
    //   modularId: "",
    //   firstname: "test",
    //   lastname: "test",
    //   mobile: "081-9732234",
    //   custId: "20388",
    //   email: "",
    //   userId: "",
    //   displayName: "",
    // };

    if (!!mobile && !!firstname && !!lastname && mobile.length === 10) {
      const res = await register(sendData);
      if (res && res.data.status) {
        setSnackbar({
          ...snackbar,
          open: true,
          severity: "success",
          message: "ลงทะเบียนสำเร็จ",
        });
        liff.closeWindow();
        setCheckBTN(false);
      }
      if (
        res &&
        !res.data.status &&
        res.data.message === "user already exists"
      ) {
        setSnackbar({
          ...snackbar,
          open: true,
          severity: "error",
          message: "รหัสนี้มีการลงทะเบียนแล้ว กรุณาติดต่อแอดมิน",
        });
        setCheckBTN(false);
      } else if (
        res &&
        !res.data.status &&
        res.data.message === "pls enter parameter"
      ) {
        setSnackbar({
          ...snackbar,
          open: true,
          severity: "error",
          message: "กรุณาคลิกลิ้งก์สมัครจากไลน์อีกครั้ง",
        });
        setCheckBTN(false);
      } else if (res && !res.data.status && res.data.message === "not dealer") {
        setSnackbar({
          ...snackbar,
          open: true,
          severity: "error",
          message: "ลงทะเบียนไม่สำเร็จ ตรวจสอบเบอร์โทรศัพท์หรือรหัสตัวแทน",
        });
        setCheckBTN(false);
      } else if (
        res &&
        !res.data.status &&
        res.data.message === "not employee"
      ) {
        setSnackbar({
          ...snackbar,
          open: true,
          severity: "error",
          message: "ลงทะเบียนไม่สำเร็จ ตรวจสอบเบอร์โทรศัพท์หรือรหัสพนักงาน",
        });
        setCheckBTN(false);
      }
      // console.log(res.data);
    } else {
      setSnackbar({
        ...snackbar,
        open: true,
        severity: "error",
        message: "กรุณากรอกข้อมูลให้ครบถ้วน",
      });
      setCheckBTN(false);
    }
  };

  const getProfile = async () => {
    const profile = await liff.getProfile();
    const email = await liff.getDecodedIDToken().email;
    console.log(profile, email);
    setData({
      ...data,
      userId: profile.userId,
      email: email,
      displayName: profile.displayName,
    });
  };

  useEffect(() => {
    // start line liff
    const modularId = searchParams.get("uId")
    Cookies.set("modularId", modularId);
    const login = async () => {
      await liff.init({ liffId: `${process.env.REACT_APP_LIFF_ID}` }, () => {
        if (liff.isLoggedIn()) {
          getProfile();
        } else {
          liff.login();
        }
      });
    };
    login();
  }, []);

  return (
    <>
      <Grid container>
        {match ? null : (
          <Grid item lg md sm xs>
            <Box
              component="img"
              alt="background"
              src={backgroundRegister}
              sx={{
                maxWidth: "100%",
                height: "auto",
                // [theme.breakpoints.down("lg")]: { height: "120%" },
              }}
            />
            <Box
              sx={{
                position: "absolute",
                top: "45%",
                left: "25%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Typography
                sx={{
                  ...theme.typography.massageRegis,
                  fontSize: "40px",
                  fontWeight: "bold",
                  [theme.breakpoints.down("lg")]: {
                    fontSize: 25,
                  },
                }}
              >
                We Serve Your Best
              </Typography>
              <Typography
                sx={{
                  fontFamily: theme.typography.massageRegis,
                  lineHeight: "25px",
                }}
              >
                provide the best products and services for you based on the
                <br />
                capacity, service availability and organizational management
                system.
                <br />
                warehouse product storage system.
              </Typography>
            </Box>
          </Grid>
        )}

        <Grid item lg md sm={12} xs={12}>
          <Box sx={{ display: "flex", justifyContent: "end", m: 1, mb: 16 }}>
            <Box
              component="img"
              alt="logo"
              src={logoOranoss}
              sx={{ width: 20, mr: 1 }}
            />
            <Typography sx={{ fontFamily: theme.typography, fontSize: 12 }}>
              Oranoss Co.,LTD.
            </Typography>
          </Box>

          <Grid container direction="column">
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Box
                  sx={{
                    width: "40%",
                    [theme.breakpoints.down("md")]: {
                      width: "75%",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      ...theme.typography.registerLabel,
                      fontsize: 24,
                      fontWeight: "500",
                      mb: 3,
                    }}
                  >
                    {signIn ? `ลงชื่อผู้ใช้` : `ลงทะเบียน`}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Box
                  sx={{
                    width: "40%",
                    [theme.breakpoints.down("md")]: {
                      width: "75%",
                    },
                  }}
                >
                  <TextField
                    name="firstname"
                    onChange={(e) => handleChange(e)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircleIcon />
                        </InputAdornment>
                      ),
                      sx: { ...theme.typography.registerLabel },
                    }}
                    InputLabelProps={{
                      sx: {
                        ...theme.typography.registerLabel,
                        fontSize: "0.8rem",
                      },
                    }}
                    label="ชื่อ"
                    size="small"
                    sx={{
                      ...theme.typography.registerLabel,
                      width: "100%",
                      mb: 3,
                    }}
                    autoComplete="off"
                    onCut={handleChangeCopy}
                    onCopy={handleChangeCopy}
                    onPaste={handleChangeCopy}
                  />
                  <TextField
                    name="lastname"
                    onChange={(e) => handleChange(e)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircleIcon />
                        </InputAdornment>
                      ),
                      sx: { ...theme.typography.registerLabel },
                    }}
                    InputLabelProps={{
                      sx: {
                        ...theme.typography.registerLabel,
                        fontSize: "0.8rem",
                      },
                    }}
                    label="นามสกุล"
                    size="small"
                    sx={{
                      ...theme.typography.registerLabel,
                      width: "100%",
                      mb: 3,
                    }}
                    autoComplete="off"
                    onCut={handleChangeCopy}
                    onCopy={handleChangeCopy}
                    onPaste={handleChangeCopy}
                  />
                  <TextField
                    name="mobile"
                    onChange={(e) => handleChange(e)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PhoneAndroidIcon />
                        </InputAdornment>
                      ),
                      sx: { ...theme.typography.registerLabel },
                    }}
                    InputLabelProps={{
                      sx: {
                        ...theme.typography.registerLabel,
                        fontSize: "0.8rem",
                      },
                    }}
                    label="เบอร์โทร"
                    size="small"
                    sx={{
                      ...theme.typography.registerLabel,
                      width: "100%",
                      mb: 3,
                    }}
                    inputProps={{ maxLength: 10 }}
                    autoComplete="off"
                    onCut={handleChangeCopy}
                    onCopy={handleChangeCopy}
                    onPaste={handleChangeCopy}
                  />
                  {signIn ? (
                    <TextField
                      name="code"
                      onChange={(e) => handleChange(e)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PasswordIcon />
                          </InputAdornment>
                        ),
                        sx: { ...theme.typography.registerLabel },
                      }}
                      InputLabelProps={{
                        sx: {
                          ...theme.typography.registerLabel,
                          fontSize: "0.8rem",
                        },
                      }}
                      label="รหัสตัวแทน / พนักงาน"
                      size="small"
                      sx={{
                        width: "100%",
                        mb: 4,
                      }}
                      autoComplete="off"
                      onCut={handleChangeCopy}
                      onCopy={handleChangeCopy}
                      onPaste={handleChangeCopy}
                    />
                  ) : null}

                  <Button
                    variant="contained"
                    onClick={() => {
                      sendRegister();
                    }}
                    sx={{
                      ...theme.typography.registerLabel,
                      width: "100%",
                      mb: 2,
                    }}
                    disabled={checkBTN === false ? false : true}
                  >
                    {signIn ? `ลงชื่อเข้าใช้` : `ลงทะเบียน`}
                  </Button>
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      sx={{
                        ...theme.typography.registerLabel,
                        fontSize: "0.8rem",
                        color: "#C4C4C4",
                        mr: 2,
                      }}
                    >
                      {signIn ? null : `มีรหัสสำหรับตัวแทน / พนักงาน ?`}
                    </Typography>
                    <Button
                      sx={{ mt: "-6px" }}
                      onClick={() => setSignIn(!signIn)}
                    >
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: "0.8rem",
                        }}
                      >
                        {signIn ? `ลงทะเบียน >` : `ลงชื่อเข้าใช้`}
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        key={vertical + horizontal}
      >
        <Alert
          onClose={handleSnackClose}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
