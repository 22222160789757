import { BrowserRouter, Routes, Route } from "react-router-dom";
import Register from "./Register";
import Receipt from "../pages/Reciept";
import Cheque from "../pages/Cheque";
import CustCredit from "../pages/CustCredit";
import ProFile from "../pages/ProFile";
import Point from "../pages/Point";
import Transport from "../pages/Transport";
import SaleHistory from "../pages/SaleHistory";
import ProductDeposit from "../pages/ProductDeposit";
import UpdateRichMenu from "../pages/UpdateRichMenu";
import RegisterDealer from "./RegisterDealer";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<RegisterDealer />} />
        <Route path="/ProductDeposit" element={<ProductDeposit />} />
        <Route path="/Cheque" element={<Cheque />} />
        <Route path="/CustCredit" element={<CustCredit />} />
        <Route path="/Profile" element={<ProFile />} />
        <Route path="/Point" element={<Point />} />
        <Route path="/Transport" element={<Transport />} />
        <Route path="/Receipt" element={<Receipt />} />
        <Route path="/SaleHistory" element={<SaleHistory />} />
        <Route path="/updateRichMenu" element={<UpdateRichMenu />} />
        <Route path="/testRegister" element={<Register />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
