import { Box, useMediaQuery, Typography, Grid, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { theme } from "./ui/theme";
import { format, parseISO } from "date-fns";

export default function DeliveryStatus(props) {
  const match = useMediaQuery(theme.breakpoints.down("md"));
  const { dataAll } = props;

  // console.log(dataAll);
  return (
    <>
      <Grid container spacing={2}>
        {match ? null : (
          <Grid
            item
            md
            lg={3}
            sm={4}
            xs={6}
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          ></Grid>
        )}

        <Grid
          item
          md={12}
          lg={6}
          sm={8}
          xs={12}
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            width: "100%",
          }}
        >
          {!!dataAll
            ? dataAll.map((data, index) => (
                <Box
                  sx={{
                    width: "100%",
                    borderRadius: 1,
                    border: 0.1,
                    borderColor: "error.main",
                    mb: 1,
                    mt: 1,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          fontWeight: "700",
                          mt: 4,
                          mb: 4,
                          textAlign: "center",
                          p: 1,
                          color: "red",

                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            fontWeight: "700",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            fontWeight: "700",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            fontWeight: "700",
                          },
                        }}
                      >
                        {!!data && !!data.INVNO ? data.INVNO : "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ mb: 2 }} />
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "left",
                          mx: 1,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                        }}
                      >
                        ครบกำหนดชำระ
                      </Typography>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "right",
                          mx: 1,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                        }}
                      >
                        {!!data && !!data.PayDueDate
                          ? format(parseISO(data.PayDueDate), "dd-MM-yyyy")
                          : "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ mb: 2, mt: 2 }} />
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "left",
                          mx: 1,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                        }}
                      >
                        สถานะใบเสร็จ
                      </Typography>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "right",
                          mx: 1,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                        }}
                      >
                        {!!data && !!data.StatusDesc ? data.StatusDesc : "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ mb: 2, mt: 2 }} />

                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "left",
                          mx: 1,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                        }}
                      >
                        วันที่ชำระ
                      </Typography>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "right",
                          mx: 1,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                        }}
                      >
                        {!!data && !!data.RCDate
                          ? format(parseISO(data.RCDate), "dd-MM-yyyy")
                          : "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ mb: 4, mt: 2 }} />
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "left",
                          mx: 1,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                        }}
                      >
                        ยอดใบแจ้งหนี้ (฿)
                      </Typography>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "right",
                          mx: 1,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                        }}
                      >
                        {!!data && !!data.INVNETTOTAL
                          ? data.INVNETTOTAL.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ mb: 2, mt: 2 }} />
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "left",
                          mx: 1,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                        }}
                      >
                        ยอดชำระแล้ว (฿)
                      </Typography>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "right",
                          mx: 1,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                        }}
                      >
                        {!!data && !!data.PayAmt
                          ? data.PayAmt.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ mb: 2, mt: 2 }} />
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "left",
                          mx: 1,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "left",
                          },
                        }}
                      >
                        ยอดค้างชำระ (฿)
                      </Typography>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <Typography
                        sx={{
                          ...theme.typography.registerLabel,
                          fontSize: 20,
                          textAlign: "right",
                          mx: 1,
                          [theme.breakpoints.down("md")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("sm")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                          [theme.breakpoints.only("xs")]: {
                            fontSize: "1rem",
                            textAlign: "right",
                          },
                        }}
                      >
                        {!!data && !!data.INVNETTOTAL
                          ? (data.INVNETTOTAL - data.PayAmt).toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )
                          : "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ mb: 4, mt: 2 }} />
                </Box>
              ))
            : []}
        </Grid>
      </Grid>
    </>
  );
}
