import axios from "axios";

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV !== "production"
      ? `${process.env.REACT_APP_BASE_URL_DEV}`
      : `${process.env.REACT_APP_BASE_URL_PROD}`,
});

export const register = (data) => {
  const response = instance.post("/register", data).catch((error) => {
    alert(error);
  });
  return response;
};
